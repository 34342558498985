<div class="rowFlexNoWrap" style="flex-wrap: nowrap; height: 90px">
  <div style="width: 20%; min-width: 180px">
    <div class="cardContainer">
      <div class="card">
        <div
          class="rowFlexNoWrap"
          style="justify-content: space-evenly; width: 100%"
        >
          <div>
            <span><strong>Dia Inicio</strong></span>
          </div>
          <div>&nbsp;</div>
          <div>
            <span><strong>Dia Fin</strong></span>
          </div>
        </div>
        <div class="rowFlexNoWrap rowInputs">
          <input
            type="number"
            name="name"
            value=""
            [(ngModel)]="minTiempoProceso"
            (blur)="EventChangeRangeTiempoProceso(minTiempoProceso, 'min')"
            (change)="EventChangeRangeTiempoProceso(minTiempoProceso, 'min')"
            [ngClass]="{ 'disabled-inputs': !unlock }"
          />
          <span><strong>-</strong></span>
          <input
            type="number"
            name="name"
            value=""
            [(ngModel)]="maxTiempoProceso"
            (blur)="EventChangeRangeTiempoProceso(maxTiempoProceso, 'max')"
            (change)="EventChangeRangeTiempoProceso(maxTiempoProceso, 'max')"
            [ngClass]="{ 'disabled-inputs': !unlock }"
          />
        </div>
        <div class="icon-circle" *ngIf="isComparacionOrExclusion == null">
          <button class="buttonTransparente">
            <img
              *ngIf="!unlock"
              class="img-icon-circle"
              src="../../../assets/icons/bloquear.png"
              alt="Alternate Text"
              title="Desbloquear dias"
              (click)="EventUnlock()"
            />
            <img
              *ngIf="unlock"
              class="img-icon-circle"
              src="../../../assets/icons/desbloquear.png"
              alt="Alternate Text"
              title="Bloquear dias"
              (click)="EventUnlock()"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="" style="width: 10%; min-width: 180px">
    <div class="rowFlexNoWrapCenter" style="">
      <div class="switchWithIcons" style="">
        <button
          class="button"
          [ngClass]="{ btnSwitchIconON: switch1, btnSwitchIconOff: !switch1 }"
          (click)="EventChangeUsdVol()"
        >
          <img
            src="../../../assets/icons/bolsa.png"
            alt="Alternate Text"
            title="USD"
          />
        </button>
        <button
          class="button"
          [ngClass]="{ btnSwitchIconON: !switch1, btnSwitchIconOff: switch1 }"
          (click)="EventChangeUsdVol()"
        >
          <img
            src="../../../assets/icons/tonelada1.png"
            alt="Alternate Text"
            title="Toneldas"
          />
        </button>
      </div>
    </div>
  </div>
  <div style="width: 70%; text-align: end; padding-right: 40px">
    <div
      class="example-button-rowFlexNoWrap rowInputs"
      style="margin-bottom: 24px"
    >
      <button
        *ngIf="isComparacionOrExclusion == null"
        class="button success"
        mat-button
        color="primary"
        (click)="EventModalExlcusion()"
        style="margin-right: 5px"
      >
        <img src="../../../assets/icons/plus.png" alt="Alternate Text" />
        Excluidos
      </button>
      <button
        *ngIf="isComparacionOrExclusion == null"
        class="button primary"
        mat-button
        color="primary"
        (click)="EventModalcomparacion()"
        style="margin-right: 5px"
      >
        <img src="../../../assets/icons/plus.png" alt="Alternate Text" />
        Comparar
      </button>
      <button
        *ngIf="!isComparacionOrExclusion"
        class="button"
        mat-button
        (click)="EventActualizarDias()"
        style="margin-right: 5px"
      >
        <img src="../../../assets/icons/save.png" alt="Alternate Text" />
        Guardar
      </button>
      <button
        *ngIf="!isComparacionOrExclusion"
        class="button"
        mat-button
        (click)="EventResetDias()"
        style="margin-right: 5px"
      >
        <!--<img src="../../../assets/icons/save.png" alt="Alternate Text" />-->
        {{ titleButton }}
      </button>
      <button class="button" mat-button (click)="EventGraphDelta()">
        <img src="../../../assets/icons/delta.png" alt="Alternate Text" />
        Delta
      </button>
    </div>
    <!--<div class="example-button-rowFlexNoWrap rowInputs">

    </div>-->
  </div>
</div>
<div class="containerGraph" style="">
  <div class="rowText" style="">
    <strong>{{ PlantillaSelected?.Nombre }}</strong>
  </div>

  <div
    #graph
    id="graph"
    [chart]="chart"
    style="width: 100%; padding-left: 50px; padding-right: 10px"
  ></div>

  <div class="flexRowNoWrap rowText">
    <div class="rowFlexNoWrapCenter" style="">
      <div class="switchWithIcons" style="width: 170px; height: 40px">
        <button
          class="button"
          style="height: 30px"
          [ngClass]="{
            btnSwitchIconON: switchUsdGestion,
            btnSwitchIconOff: !switchUsdGestion
          }"
          (click)="switchUsdGestion = !switchUsdGestion"
        >
          <!--<img src="../../../assets/icons/tabla.png" alt="Alternate Text" />-->
          <strong>USD</strong>
        </button>
        <button
          class="button"
          style="height: 30px"
          [ngClass]="{
            btnSwitchIconON: !switchUsdGestion,
            btnSwitchIconOff: switchUsdGestion
          }"
          (click)="switchUsdGestion = !switchUsdGestion"
        >
          <!--<img src="../../../assets/icons/graph.png" alt="Alternate Text" />-->
          <strong>Gestión</strong>
        </button>
      </div>
      <button
        class="button primary"
        mat-button
        color="primary"
        (click)="MostrarCostosLogisticos()"
        style="margin-left: 20px"
      >
        <img src="../../../assets/icons/plus.png" alt="Alternate Text" />
        Costos Logístico
      </button>
    </div>

    <div class="rowFlexNoWrapCenter" style="margin-left: auto">
      <div class="switchWithIcons" style="width: 170px; height: 40px">
        <button
          class="button"
          style="height: 30px"
          [ngClass]="{
            btnSwitchIconON: switchTablaOrGraph,
            btnSwitchIconOff: !switchTablaOrGraph
          }"
          (click)="
            switchTablaOrGraph = !switchTablaOrGraph; EventChangeTableOrGraph()
          "
        >
          <img src="../../../assets/icons/tabla.png" alt="Alternate Text" />
          Tabla
        </button>
        <button
          class="button"
          style="height: 30px"
          [ngClass]="{
            btnSwitchIconON: !switchTablaOrGraph,
            btnSwitchIconOff: switchTablaOrGraph
          }"
          (click)="
            switchTablaOrGraph = !switchTablaOrGraph; EventChangeTableOrGraph()
          "
        >
          <img src="../../../assets/icons/graph.png" alt="Alternate Text" />
          Gráfico
        </button>
      </div>
    </div>
  </div>

  <!--************GRID***********-->

  <div class="SectionGrids">
    <div style="">
      <!--************Encabezado***********-->

      <div
        class="flexRowNoWrap EncabezadoGridR1"
        style="justify-content: space-between; flex-wrap: nowrap"
      >
        <div class="cellListP" style="width: 40px; min-width: 40px">
          <div class="cellListE1">&nbsp;</div>
        </div>

        <div
          class="cellListP"
          style="width: 100px; min-width: 100px"
          [ngStyle]="{ 'min-width': switchUsdGestion ? '100px' : '200px' }"
        >
          <div class="cellListE1"><span>Volumen</span></div>
        </div>

        <div
          *ngIf="switchUsdGestion"
          class="cellListP"
          style="width: 100px; min-width: 100px"
        >
          <div class="cellListE1"><span>USD</span></div>
        </div>

        <div class="cellListP">
          <div class="cellListE1"><span>Orígen</span></div>
        </div>

        <div class="cellListP">
          <div class="cellListE1"><span>Tránsito 1</span></div>
        </div>

        <div class="cellListP">
          <div class="cellListE1"><span>Frontera</span></div>
        </div>

        <div class="cellListP">
          <div class="cellListE1"><span>Transito 2</span></div>
        </div>

        <div class="cellListP">
          <div class="cellListE1"><span>Destino</span></div>
        </div>
      </div>
    </div>
    <!--************Body grids***********-->
    <div>
      <div class="subsection" style="">
        <div *ngFor="let item of listCostosFactura; let i = index">
          <div class="flexRowNoWrap rowGrid">
            <div class="cellListP" style="width: 40px; min-width: 40px">
              <div
                class="cellListE1 c-pointer"
                (click)="
                  item.Expandible = !item.Expandible; EventChangeTableOrGraph()
                "
              >
                <img
                  *ngIf="!item.Expandible"
                  src="../../../assets/icons/arrowDownBlack1.png"
                  alt="Alternate Text"
                />
                <img
                  *ngIf="item.Expandible"
                  src="../../../assets/icons/arrowUpBlack1.png"
                  alt="Alternate Text"
                />
              </div>
            </div>

            <div
              class="cellListP cursor c-pointer"
              style="width: 100px; min-width: 100px"
              (click)="EventModalGestiones('all')"
              [ngStyle]="{ 'min-width': switchUsdGestion ? '100px' : '200px' }"
            >
              <div class="cellListE1">
                <span>{{ item.Volumen | commaDecimal }}</span>
              </div>
            </div>

            <div
              *ngIf="switchUsdGestion"
              class="cellListP"
              style="width: 100px; min-width: 100px"
            >
              <div class="cellListE1">
                <span>${{ item.USD | commaDecimal }}</span>
              </div>
            </div>

            <div
              class="cellListP cursor c-pointer"
              style=""
              (click)="EventModalGestiones('Origen')"
            >
              <div *ngIf="switchUsdGestion" class="cellListE1">
                <span>${{ item.Origen | commaDecimal }}</span>
              </div>
              <div *ngIf="!switchUsdGestion" class="cellListE1">
                <span>{{ item.OrigenGestiones }}</span>
              </div>
            </div>

            <div
              class="cellListP cursor c-pointer"
              style=""
              (click)="EventModalGestiones('TransitoUno')"
            >
              <div *ngIf="switchUsdGestion" class="cellListE1">
                <span>${{ item.TransitoUno | commaDecimal }}</span>
              </div>
              <div *ngIf="!switchUsdGestion" class="cellListE1">
                <span>{{ item.TransitoUnoGestiones }}</span>
              </div>
            </div>

            <div
              class="cellListP cursor c-pointer"
              style=""
              (click)="EventModalGestiones('Frontera')"
            >
              <div *ngIf="switchUsdGestion" class="cellListE1">
                <span>${{ item.Frontera | commaDecimal }}</span>
              </div>
              <div *ngIf="!switchUsdGestion" class="cellListE1">
                <span>{{ item.FronteraGestiones }}</span>
              </div>
            </div>

            <div
              class="cellListP cursor c-pointer"
              style=""
              (click)="EventModalGestiones('TransitoDos')"
            >
              <div *ngIf="switchUsdGestion" class="cellListE1">
                <span>${{ item.TransitoDos | commaDecimal }}</span>
              </div>
              <div *ngIf="!switchUsdGestion" class="cellListE1">
                <span>{{ item.TransitoDosGestiones }}</span>
              </div>
            </div>

            <div
              class="cellListP cursor c-pointer"
              style=""
              (click)="EventModalGestiones('Destino')"
            >
              <div *ngIf="switchUsdGestion" class="cellListE1">
                <span>${{ item.Destino | commaDecimal }}</span>
              </div>
              <div *ngIf="!switchUsdGestion" class="cellListE1">
                <span>{{ item.DestinoGestiones }}</span>
              </div>
            </div>
          </div>

          <div
            *ngIf="switchTablaOrGraph && item.Expandible"
            style="margin-top: 20px"
          >
            <!--*****Cuando se cambia el switch a Tabla-->
            <!--/////////****GRid Expandible 1***-->

            <!--************Filtros***********-->
            <div
              class="flexRowNoWrap rowText"
              style="justify-content: flex-start; margin-bottom: 20px"
            >
              <div
                class="rowFlexNoWrapCenter"
                style="justify-content: flex-start; align-items: center"
              >
                <div class="switchWithIcons" style="width: 270px; height: 40px">
                  <!-- Botón Filtro SKU -->
                  <button
                    class="button"
                    style="height: 30px"
                    [ngClass]="{
                      btnSwitchIconON: esFiltroActivo('sku'),
                      btnSwitchIconOff: !esFiltroActivo('sku')
                    }"
                    (click)="activarFiltro('sku')"
                  >
                    <strong>SKU</strong>
                  </button>

                  <!-- Botón Filtro Origen -->
                  <button
                    class="button"
                    style="height: 30px"
                    [ngClass]="{
                      btnSwitchIconON: esFiltroActivo('origen'),
                      btnSwitchIconOff: !esFiltroActivo('origen')
                    }"
                    (click)="activarFiltro('origen')"
                  >
                    <strong>Origen</strong>
                  </button>

                  <!-- Botón Filtro Destino -->
                  <button
                    class="button"
                    style="height: 30px"
                    [ngClass]="{
                      btnSwitchIconON: esFiltroActivo('destino'),
                      btnSwitchIconOff: !esFiltroActivo('destino')
                    }"
                    (click)="activarFiltro('destino')"
                  >
                    <strong>Destino</strong>
                  </button>

                  <!-- Botón Filtro País Destino -->
                  <button
                    class="button"
                    style="height: 30px"
                    [ngClass]="{
                      btnSwitchIconON: esFiltroActivo('paisDestino'),
                      btnSwitchIconOff: !esFiltroActivo('paisDestino')
                    }"
                    (click)="activarFiltro('paisDestino')"
                  >
                    <strong>País Destino</strong>
                  </button>
                </div>
              </div>
            </div>

            <div
              *ngIf="item.Expandible && esFiltroActivo('sku')"
              class="SectionGrids"
            >
              <div style="">
                <!--************Encabezado***********-->
                <div
                  class="flexRowNoWrap EncabezadoGridR1"
                  style="justify-content: space-between; flex-wrap: nowrap"
                >
                  <div class="cellListP" style="width: 40px; min-width: 40px">
                    <div class="cellListE1">&nbsp;</div>
                  </div>

                  <div
                    class="cellListP"
                    style="width: 100px; min-width: 100px"
                    [ngStyle]="{
                      'min-width': switchUsdGestion ? '100px' : '200px'
                    }"
                  >
                    <div class="cellListE1"><span>Categoria</span></div>
                  </div>

                  <div
                    *ngIf="switchUsdGestion"
                    class="cellListP"
                    style="width: 100px; min-width: 100px"
                  >
                    <div class="cellListE1"><span>USD</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Orígen</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Tránsito 1</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Frontera</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Transito 2</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Destino</span></div>
                  </div>
                </div>
              </div>
              <!--************Body grids***********-->
              <div>
                <div class="subsection" style="">
                  <div
                    *ngFor="let categoria of item.ListCategorys; let i = index"
                  >
                    <div class="flexRowNoWrap rowGrid">
                      <div
                        class="cellListP"
                        style="width: 40px; min-width: 40px"
                      >
                        <div
                          class="cellListE1 c-pointer"
                          (click)="categoria.Expandible = !categoria.Expandible"
                        >
                          <img
                            *ngIf="!categoria.Expandible"
                            src="../../../assets/icons/arrowDownBlack1.png"
                            alt="Alternate Text"
                          />
                          <img
                            *ngIf="categoria.Expandible"
                            src="../../../assets/icons/arrowUpBlack1.png"
                            alt="Alternate Text"
                          />
                        </div>
                      </div>

                      <div
                        class="cellListP"
                        style="width: 100px; min-width: 100px"
                        [ngStyle]="{
                          'min-width': switchUsdGestion ? '100px' : '200px'
                        }"
                      >
                        <div class="cellListE1">
                          <span>{{ categoria.Categoria }}</span>
                        </div>
                      </div>

                      <div
                        *ngIf="switchUsdGestion"
                        class="cellListP"
                        style="width: 100px; min-width: 100px"
                      >
                        <div class="cellListE1">
                          <span>${{ categoria.USD | commaDecimal }}</span>
                        </div>
                      </div>

                      <div class="cellListP" style="">
                        <div *ngIf="switchUsdGestion" class="cellListE1">
                          <span>${{ categoria.Origen | commaDecimal }}</span>
                        </div>
                        <div *ngIf="!switchUsdGestion" class="cellListE1">
                          <span>{{ categoria.OrigenGestiones }}</span>
                        </div>
                      </div>

                      <div class="cellListP" style="">
                        <div *ngIf="switchUsdGestion" class="cellListE1">
                          <span
                            >${{ categoria.TransitoUno | commaDecimal }}</span
                          >
                        </div>
                        <div *ngIf="!switchUsdGestion" class="cellListE1">
                          <span>{{ categoria.TransitoUnoGestiones }}</span>
                        </div>
                      </div>

                      <div class="cellListP" style="">
                        <div *ngIf="switchUsdGestion" class="cellListE1">
                          <span>${{ categoria.Frontera | commaDecimal }}</span>
                        </div>
                        <div *ngIf="!switchUsdGestion" class="cellListE1">
                          <span>{{ categoria.FronteraGestiones }}</span>
                        </div>
                      </div>

                      <div class="cellListP" style="">
                        <div *ngIf="switchUsdGestion" class="cellListE1">
                          <span
                            >${{ categoria.TransitoDos | commaDecimal }}</span
                          >
                        </div>
                        <div *ngIf="!switchUsdGestion" class="cellListE1">
                          <span>{{ categoria.TransitoDosGestiones }}</span>
                        </div>
                      </div>

                      <div class="cellListP" style="">
                        <div *ngIf="switchUsdGestion" class="cellListE1">
                          <span>${{ categoria.Destino | commaDecimal }}</span>
                        </div>
                        <div *ngIf="!switchUsdGestion" class="cellListE1">
                          <span>{{ categoria.DestinoGestiones }}</span>
                        </div>
                      </div>
                    </div>

                    <!--/////////****GRid Expandible 2***-->

                    <div *ngIf="categoria.Expandible" class="SectionGrids">
                      <div style="">
                        <!--************Encabezado***********-->

                        <div
                          class="flexRowNoWrap EncabezadoGridR1"
                          style="
                            justify-content: space-between;
                            flex-wrap: nowrap;
                          "
                        >
                          <div
                            class="cellListP"
                            style="width: 40px; min-width: 40px"
                          >
                            <div class="cellListE1">&nbsp;</div>
                          </div>

                          <div
                            class="cellListP"
                            style="width: 100px; min-width: 100px"
                            [ngStyle]="{
                              'min-width': switchUsdGestion ? '100px' : '200px'
                            }"
                          >
                            <div class="cellListE1"><span>Sku</span></div>
                          </div>

                          <div
                            *ngIf="switchUsdGestion"
                            class="cellListP"
                            style="width: 100px; min-width: 100px"
                          >
                            <div class="cellListE1"><span>USD</span></div>
                          </div>

                          <div class="cellListP">
                            <div class="cellListE1"><span>Orígen</span></div>
                          </div>

                          <div class="cellListP">
                            <div class="cellListE1">
                              <span>Tránsito 1</span>
                            </div>
                          </div>

                          <div class="cellListP">
                            <div class="cellListE1"><span>Frontera</span></div>
                          </div>

                          <div class="cellListP">
                            <div class="cellListE1">
                              <span>Transito 2</span>
                            </div>
                          </div>

                          <div class="cellListP">
                            <div class="cellListE1"><span>Destino</span></div>
                          </div>
                        </div>
                      </div>
                      <!--************Body grids***********-->
                      <div>
                        <div class="subsection" style="">
                          <div
                            *ngFor="
                              let skus of categoria.Listdos;
                              let i = index
                            "
                          >
                            <div class="flexRowNoWrap rowGrid">
                              <!--<div class="cellListP " style=" width: 40px; min-width: 40px;">
                                <div class="cellListE1 " (click)="categoria.Expandible = !categoria.Expandible">
                                  <img *ngIf="!categoria.Expandible" src="../../../assets/icons/arrowDownBlack1.png" alt="Alternate Text" />
                                  <img *ngIf="categoria.Expandible" src="../../../assets/icons/arrowUpBlack1.png" alt="Alternate Text" />
                                </div>
                              </div>-->

                              <div
                                class="cellListP"
                                style="width: 40px; min-width: 40px"
                              >
                                <div class="cellListE1">&nbsp;</div>
                              </div>

                              <div
                                class="cellListP"
                                style="width: 100px; min-width: 100px"
                                [ngStyle]="{
                                  'min-width': switchUsdGestion
                                    ? '100px'
                                    : '200px'
                                }"
                              >
                                <div class="cellListE1">
                                  <span>{{ skus.Descripcion }}</span>
                                </div>
                              </div>

                              <div
                                *ngIf="switchUsdGestion"
                                class="cellListP"
                                style="width: 100px; min-width: 100px"
                              >
                                <div class="cellListE1">
                                  <span>${{ skus.USD | commaDecimal }}</span>
                                </div>
                              </div>

                              <div class="cellListP" style="">
                                <div
                                  *ngIf="switchUsdGestion"
                                  class="cellListE1"
                                >
                                  <span>${{ skus.Origen | commaDecimal }}</span>
                                </div>
                                <div
                                  *ngIf="!switchUsdGestion"
                                  class="cellListE1"
                                >
                                  <span>{{ skus.OrigenGestiones }}</span>
                                </div>
                              </div>

                              <div class="cellListP" style="">
                                <div
                                  *ngIf="switchUsdGestion"
                                  class="cellListE1"
                                >
                                  <span
                                    >${{
                                      skus.TransitoUno | commaDecimal
                                    }}</span
                                  >
                                </div>
                                <div
                                  *ngIf="!switchUsdGestion"
                                  class="cellListE1"
                                >
                                  <span>{{ skus.TransitoUnoGestiones }}</span>
                                </div>
                              </div>

                              <div class="cellListP" style="">
                                <div
                                  *ngIf="switchUsdGestion"
                                  class="cellListE1"
                                >
                                  <span
                                    >${{ skus.Frontera | commaDecimal }}</span
                                  >
                                </div>
                                <div
                                  *ngIf="!switchUsdGestion"
                                  class="cellListE1"
                                >
                                  <span>{{ skus.FronteraGestiones }}</span>
                                </div>
                              </div>

                              <div class="cellListP" style="">
                                <div
                                  *ngIf="switchUsdGestion"
                                  class="cellListE1"
                                >
                                  <span
                                    >${{
                                      skus.TransitoDos | commaDecimal
                                    }}</span
                                  >
                                </div>
                                <div
                                  *ngIf="!switchUsdGestion"
                                  class="cellListE1"
                                >
                                  <span>{{ skus.TransitoDosGestiones }}</span>
                                </div>
                              </div>

                              <div class="cellListP" style="">
                                <div
                                  *ngIf="switchUsdGestion"
                                  class="cellListE1"
                                >
                                  <span
                                    >${{ skus.Destino | commaDecimal }}</span
                                  >
                                </div>
                                <div
                                  *ngIf="!switchUsdGestion"
                                  class="cellListE1"
                                >
                                  <span>{{ skus.DestinoGestiones }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!--/////////****Fin GRid Expandible 2***-->
                  </div>
                </div>
              </div>
            </div>

            <!--************Filtro de Origen***********-->

            <div
              *ngIf="item.Expandible && esFiltroActivo('origen')"
              class="SectionGrids"
            >
              <div style="">
                <!--************Encabezado***********-->
                <div
                  class="flexRowNoWrap EncabezadoGridR1"
                  style="justify-content: space-between; flex-wrap: nowrap"
                >
                  <div class="cellListP" style="width: 40px; min-width: 40px">
                    <div class="cellListE1">&nbsp;</div>
                  </div>

                  <div class="cellListP" style="width: 100px; min-width: 200px">
                    <div class="cellListE1"><span>Sitio Origen</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Orígen</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Tránsito 1</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Frontera</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Transito 2</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Destino</span></div>
                  </div>
                </div>
              </div>

              <!--************Body grids***********-->
              <div>
                <div class="subsection">
                  <div *ngFor="let sitio of listOrigen; let i = index">
                    <div class="flexRowNoWrap rowGrid">
                      <div
                        class="cellListP"
                        style="width: 40px; min-width: 40px"
                      >
                        <div class="cellListE1">&nbsp;</div>
                      </div>
                      <!-- NombreRuta -->
                      <div
                        class="cellListP"
                        style="width: 100px; min-width: 200px"
                      >
                        <div class="cellListE1">
                          <span>{{ sitio.NombreRuta }}</span>
                        </div>
                      </div>
                      <!-- Origen -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.Origen }}</span>
                        </div>
                      </div>

                      <!-- Transito 1 -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.TransitoUno }}</span>
                        </div>
                      </div>

                      <!-- Frontera -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.Frontera }}</span>
                        </div>
                      </div>

                      <!-- Transito 2 -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.TransitoDos }}</span>
                        </div>
                      </div>

                      <!-- Destino -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.Destino }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--************Filtro de Destino***********-->

            <div
              *ngIf="item.Expandible && esFiltroActivo('destino')"
              class="SectionGrids"
            >
              <div style="">
                <!--************Encabezado***********-->
                <div
                  class="flexRowNoWrap EncabezadoGridR1"
                  style="justify-content: space-between; flex-wrap: nowrap"
                >
                  <div class="cellListP" style="width: 40px; min-width: 40px">
                    <div class="cellListE1">&nbsp;</div>
                  </div>

                  <div class="cellListP" style="width: 100px; min-width: 200px">
                    <div class="cellListE1"><span>Sitio Destino</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Orígen</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Tránsito 1</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Frontera</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Transito 2</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Destino</span></div>
                  </div>
                </div>
              </div>

              <!--************Body grids***********-->
              <div>
                <div class="subsection">
                  <div *ngFor="let sitio of listDestino; let i = index">
                    <div class="flexRowNoWrap rowGrid">
                      <div
                        class="cellListP"
                        style="width: 40px; min-width: 40px"
                      >
                        <div class="cellListE1">&nbsp;</div>
                      </div>
                      <!-- NombreRuta -->
                      <div
                        class="cellListP"
                        style="width: 100px; min-width: 200px"
                      >
                        <div class="cellListE1">
                          <span>{{ sitio.NombreRuta }}</span>
                        </div>
                      </div>
                      <!-- Origen -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.Origen }}</span>
                        </div>
                      </div>

                      <!-- Transito 1 -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.TransitoUno }}</span>
                        </div>
                      </div>

                      <!-- Frontera -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.Frontera }}</span>
                        </div>
                      </div>

                      <!-- Transito 2 -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.TransitoDos }}</span>
                        </div>
                      </div>

                      <!-- Destino -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.Destino }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--************Filtro PaisDestino***********-->

            <div
              *ngIf="item.Expandible && esFiltroActivo('paisDestino')"
              class="SectionGrids"
            >
              <div style="">
                <!--************Encabezado***********-->
                <div
                  class="flexRowNoWrap EncabezadoGridR1"
                  style="justify-content: space-between; flex-wrap: nowrap"
                >
                  <div class="cellListP" style="width: 40px; min-width: 40px">
                    <div class="cellListE1">&nbsp;</div>
                  </div>

                  <div class="cellListP" style="width: 100px; min-width: 200px">
                    <div class="cellListE1"><span>Sitio Destino</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Orígen</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Tránsito 1</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Frontera</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Transito 2</span></div>
                  </div>

                  <div class="cellListP">
                    <div class="cellListE1"><span>Destino</span></div>
                  </div>
                </div>
              </div>

              <!--************Body grids***********-->
              <div>
                <div class="subsection">
                  <div *ngFor="let sitio of listPaisDestino; let i = index">
                    <div class="flexRowNoWrap rowGrid">
                      <div
                        class="cellListP"
                        style="width: 40px; min-width: 40px"
                      >
                        <div
                          class="cellListE1 c-pointer"
                          (click)="sitio.Expandible = !sitio.Expandible"
                        >
                          <img
                            *ngIf="!sitio.Expandible"
                            src="../../../assets/icons/arrowDownBlack1.png"
                            alt="Alternate Text"
                          />
                          <img
                            *ngIf="sitio.Expandible"
                            src="../../../assets/icons/arrowUpBlack1.png"
                            alt="Alternate Text"
                          />
                        </div>
                      </div>
                      <!-- NombreRuta -->
                      <div
                        class="cellListP"
                        style="width: 100px; min-width: 200px"
                      >
                        <div class="cellListE1">
                          <span>{{ sitio.NombreRuta }}</span>
                        </div>
                      </div>
                      <!-- Origen -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.Origen }}</span>
                        </div>
                      </div>

                      <!-- Transito 1 -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.TransitoUno }}</span>
                        </div>
                      </div>

                      <!-- Frontera -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.Frontera }}</span>
                        </div>
                      </div>

                      <!-- Transito 2 -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.TransitoDos }}</span>
                        </div>
                      </div>

                      <!-- Destino -->
                      <div class="cellListP">
                        <div class="cellListE1">
                          <span>{{ sitio.Destino }}</span>
                        </div>
                      </div>
                    </div>

                    <!-- Tabla anidada de Pais Destino -->
                    <div *ngIf="sitio.Expandible" class="SectionGrids">
                      <div style="">
                        <!--************Encabezado***********-->
                        <div
                          class="flexRowNoWrap EncabezadoGridR1"
                          style="
                            justify-content: space-between;
                            flex-wrap: nowrap;
                          "
                        >
                          <div
                            class="cellListP"
                            style="width: 40px; min-width: 40px"
                          >
                            <div class="cellListE1">&nbsp;</div>
                          </div>

                          <div
                            class="cellListP"
                            style="width: 100px; min-width: 200px"
                          >
                            <div class="cellListE1">
                              <span>Sitio Destino</span>
                            </div>
                          </div>

                          <div class="cellListP">
                            <div class="cellListE1"><span>Orígen</span></div>
                          </div>

                          <div class="cellListP">
                            <div class="cellListE1">
                              <span>Tránsito 1</span>
                            </div>
                          </div>

                          <div class="cellListP">
                            <div class="cellListE1"><span>Frontera</span></div>
                          </div>

                          <div class="cellListP">
                            <div class="cellListE1">
                              <span>Transito 2</span>
                            </div>
                          </div>

                          <div class="cellListP">
                            <div class="cellListE1"><span>Destino</span></div>
                          </div>
                        </div>
                      </div>

                      <!--************Body grids***********-->
                      <div>
                        <div class="subsection">
                          <div
                            *ngFor="
                              let sitioDestinos of sitio.SitiosDestino;
                              let i = index
                            "
                          >
                            <div class="flexRowNoWrap rowGrid">
                              <div
                                class="cellListP"
                                style="width: 40px; min-width: 40px"
                              >
                                <div class="cellListE1">&nbsp;</div>
                              </div>
                              <!-- NombreRuta -->
                              <div
                                class="cellListP"
                                style="width: 100px; min-width: 200px"
                              >
                                <div class="cellListE1">
                                  <span>{{ sitioDestinos.DestinoNombre }}</span>
                                </div>
                              </div>
                              <!-- Origen -->
                              <div class="cellListP">
                                <div class="cellListE1">
                                  <span>{{ sitioDestinos.Origen }}</span>
                                </div>
                              </div>

                              <!-- Transito 1 -->
                              <div class="cellListP">
                                <div class="cellListE1">
                                  <span>{{ sitioDestinos.TransitoUno }}</span>
                                </div>
                              </div>

                              <!-- Frontera -->
                              <div class="cellListP">
                                <div class="cellListE1">
                                  <span>{{ sitioDestinos.Frontera }}</span>
                                </div>
                              </div>

                              <!-- Transito 2 -->
                              <div class="cellListP">
                                <div class="cellListE1">
                                  <span>{{ sitioDestinos.TransitoDos }}</span>
                                </div>
                              </div>

                              <!-- Destino -->
                              <div class="cellListP">
                                <div class="cellListE1">
                                  <span>{{ sitioDestinos.Destino }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!--/////////****Fin GRid Expandible 1***-->
          </div>
          <div *ngIf="!switchTablaOrGraph && item.Expandible">
            <!--*****Cuando se cambia el switch a Grafica-->
            <div class="SectionGrids">
              <graph-grid-switch
                #graphCalorInfo
                [dataGestiones]="dataGestiones"
              ></graph-grid-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/////////****Fin GRid***-->
  <div *ngIf="mostrarCostosLogisticos" class="">
    <div class="rowText">
      <span><strong>Costos Logísticos</strong></span>
    </div>
  </div>

  <!--************GRID***********-->

  <div *ngIf="mostrarCostosLogisticos" class="SectionGrids">
    <div style="">
      <!--************Encabezado***********-->

      <div
        class="flexRowNoWrap EncabezadoGridR1"
        style="justify-content: space-between; flex-wrap: nowrap"
      >
        <div class="cellListP" style="width: 40px; min-width: 40px">
          <div class="cellListE1">&nbsp;</div>
        </div>

        <div class="cellListP" style="width: 100px; min-width: 100px">
          <div class="cellListE1"><span>Volumen</span></div>
        </div>

        <div class="cellListP" style="width: 100px; min-width: 100px">
          <div class="cellListE1"><span>USD</span></div>
        </div>

        <div class="cellListP">
          <div class="cellListE1"><span>Orígen</span></div>
        </div>

        <div class="cellListP">
          <div class="cellListE1"><span>Tránsito 1</span></div>
        </div>

        <div class="cellListP">
          <div class="cellListE1"><span>Frontera</span></div>
        </div>

        <div class="cellListP">
          <div class="cellListE1"><span>Transito 2</span></div>
        </div>

        <div class="cellListP">
          <div class="cellListE1"><span>Destino</span></div>
        </div>
      </div>
    </div>
    <!--************Body grids***********-->
    <div>
      <div class="subsection" style="">
        <div *ngFor="let item of listCostosLog; let i = index">
          <div class="flexRowNoWrap rowGrid">
            <div class="cellListP" style="width: 40px; min-width: 40px">
              <div
                class="cellListE1 c-pointer"
                (click)="item.Expandible = !item.Expandible"
              >
                <img
                  *ngIf="!item.Expandible"
                  src="../../../assets/icons/arrowDownBlack1.png"
                  alt="Alternate Text"
                />
                <img
                  *ngIf="item.Expandible"
                  src="../../../assets/icons/arrowUpBlack1.png"
                  alt="Alternate Text"
                />
              </div>
            </div>

            <div class="cellListP" style="width: 100px; min-width: 100px">
              <div class="cellListE1">
                <span>{{ item.Volumen }}</span>
              </div>
            </div>

            <div class="cellListP" style="width: 100px; min-width: 100px">
              <div class="cellListE1">
                <span>${{ item.USD | commaDecimal }}</span>
              </div>
            </div>

            <div class="cellListP" style="">
              <div class="cellListE1">
                <span>${{ item.Origen | commaDecimal }}</span>
              </div>
            </div>

            <div class="cellListP" style="">
              <div class="cellListE1">
                <span>${{ item.TransitoUno | commaDecimal }}</span>
              </div>
            </div>

            <div class="cellListP" style="">
              <div class="cellListE1">
                <span>${{ item.Frontera | commaDecimal }}</span>
              </div>
            </div>

            <div class="cellListP" style="">
              <div class="cellListE1">
                <span>${{ item.TransitoDos | commaDecimal }}</span>
              </div>
            </div>

            <div class="cellListP" style="">
              <div class="cellListE1">
                <span>${{ item.Destino | commaDecimal }}</span>
              </div>
            </div>
          </div>

          <!--**********expandible 1-->
          <div *ngIf="item.Expandible" class="SectionGrids">
            <div style="">
              <!--************Encabezado***********-->
              <div
                class="flexRowNoWrap EncabezadoGridR1"
                style="justify-content: space-between; flex-wrap: nowrap"
              >
                <div class="cellListP" style="width: 40px; min-width: 40px">
                  <div class="cellListE1">&nbsp;</div>
                </div>

                <div
                  class="cellListP"
                  style="width: 100px; min-width: 100px"
                  [ngStyle]="{
                    'min-width': switchUsdGestion ? '100px' : '200px'
                  }"
                >
                  <div class="cellListE1"><span>Categoria</span></div>
                </div>

                <div
                  *ngIf="switchUsdGestion"
                  class="cellListP"
                  style="width: 100px; min-width: 100px"
                >
                  <div class="cellListE1"><span>USD</span></div>
                </div>

                <div class="cellListP">
                  <div class="cellListE1"><span>Orígen</span></div>
                </div>

                <div class="cellListP">
                  <div class="cellListE1"><span>Tránsito 1</span></div>
                </div>

                <div class="cellListP">
                  <div class="cellListE1"><span>Frontera</span></div>
                </div>

                <div class="cellListP">
                  <div class="cellListE1"><span>Transito 2</span></div>
                </div>

                <div class="cellListP">
                  <div class="cellListE1"><span>Destino</span></div>
                </div>
              </div>
            </div>
            <!--************Body grids***********-->
            <div>
              <div class="subsection" style="">
                <div
                  *ngFor="let categoria of item.ListCategorys; let i = index"
                >
                  <div class="flexRowNoWrap rowGrid">
                    <div class="cellListP" style="width: 40px; min-width: 40px">
                      <div
                        class="cellListE1 c-pointer"
                        (click)="categoria.Expandible = !categoria.Expandible"
                      >
                        <img
                          *ngIf="!categoria.Expandible"
                          src="../../../assets/icons/arrowDownBlack1.png"
                          alt="Alternate Text"
                        />
                        <img
                          *ngIf="categoria.Expandible"
                          src="../../../assets/icons/arrowUpBlack1.png"
                          alt="Alternate Text"
                        />
                      </div>
                    </div>

                    <div
                      class="cellListP"
                      style="width: 100px; min-width: 100px"
                      [ngStyle]="{
                        'min-width': switchUsdGestion ? '100px' : '200px'
                      }"
                    >
                      <div class="cellListE1">
                        <span>{{ categoria.Categoria }}</span>
                      </div>
                    </div>

                    <div
                      *ngIf="switchUsdGestion"
                      class="cellListP"
                      style="width: 100px; min-width: 100px"
                    >
                      <div class="cellListE1">
                        <span>${{ categoria.USD | commaDecimal }}</span>
                      </div>
                    </div>

                    <div class="cellListP" style="">
                      <div class="cellListE1">
                        <span>${{ categoria.Origen | commaDecimal }}</span>
                      </div>
                      <!--<div *ngIf="!switchUsdGestion" class="cellListE1 "><span>{{categoria.OrigenGestiones }}</span></div>-->
                    </div>

                    <div class="cellListP" style="">
                      <div class="cellListE1">
                        <span>${{ categoria.TransitoUno | commaDecimal }}</span>
                      </div>
                      <!--<div *ngIf="!switchUsdGestion" class="cellListE1 "><span>{{categoria.TransitoUnoGestiones }}</span></div>-->
                    </div>

                    <div class="cellListP" style="">
                      <div class="cellListE1">
                        <span>${{ categoria.Frontera | commaDecimal }}</span>
                      </div>
                      <!--<div *ngIf="!switchUsdGestion" class="cellListE1 "><span>{{categoria.FronteraGestiones }}</span></div>-->
                    </div>

                    <div class="cellListP" style="">
                      <div class="cellListE1">
                        <span>${{ categoria.TransitoDos | commaDecimal }}</span>
                      </div>
                      <!--<div *ngIf="!switchUsdGestion" class="cellListE1 "><span>{{categoria.TransitoDosGestiones }}</span></div>-->
                    </div>

                    <div class="cellListP" style="">
                      <div class="cellListE1">
                        <span>${{ categoria.Destino | commaDecimal }}</span>
                      </div>
                      <!--<div *ngIf="!switchUsdGestion" class="cellListE1 "><span>{{categoria.DestinoGestiones }}</span></div>-->
                    </div>
                  </div>

                  <!--/////////****GRid Expandible 2***-->

                  <div *ngIf="categoria.Expandible" class="SectionGrids">
                    <div style="">
                      <!--************Encabezado***********-->

                      <div
                        class="flexRowNoWrap EncabezadoGridR1"
                        style="
                          justify-content: space-between;
                          flex-wrap: nowrap;
                        "
                      >
                        <div
                          class="cellListP"
                          style="width: 40px; min-width: 40px"
                        >
                          <div class="cellListE1">&nbsp;</div>
                        </div>

                        <div
                          class="cellListP"
                          style="width: 100px; min-width: 100px"
                          [ngStyle]="{
                            'min-width': switchUsdGestion ? '100px' : '200px'
                          }"
                        >
                          <div class="cellListE1"><span>Descripcion</span></div>
                        </div>

                        <div
                          *ngIf="switchUsdGestion"
                          class="cellListP"
                          style="width: 100px; min-width: 100px"
                        >
                          <div class="cellListE1"><span>USD</span></div>
                        </div>

                        <div class="cellListP">
                          <div class="cellListE1"><span>Orígen</span></div>
                        </div>

                        <div class="cellListP">
                          <div class="cellListE1"><span>Tránsito 1</span></div>
                        </div>

                        <div class="cellListP">
                          <div class="cellListE1"><span>Frontera</span></div>
                        </div>

                        <div class="cellListP">
                          <div class="cellListE1"><span>Transito 2</span></div>
                        </div>

                        <div class="cellListP">
                          <div class="cellListE1"><span>Destino</span></div>
                        </div>
                      </div>
                    </div>
                    <!--************Body grids***********-->
                    <div>
                      <div class="subsection" style="">
                        <div
                          *ngFor="
                            let itemsDos of categoria.Listdos;
                            let i = index
                          "
                        >
                          <div class="flexRowNoWrap rowGrid">
                            <!--<div class="cellListP " style=" width: 40px; min-width: 40px;">
                      <div class="cellListE1 " (click)="categoria.Expandible = !categoria.Expandible">
                        <img *ngIf="!categoria.Expandible" src="../../../assets/icons/arrowDownBlack1.png" alt="Alternate Text" />
                        <img *ngIf="categoria.Expandible" src="../../../assets/icons/arrowUpBlack1.png" alt="Alternate Text" />
                      </div>
                    </div>-->

                            <div
                              class="cellListP"
                              style="width: 40px; min-width: 40px"
                            >
                              <div class="cellListE1">&nbsp;</div>
                            </div>

                            <div
                              class="cellListP"
                              style="width: 100px; min-width: 100px"
                              [ngStyle]="{
                                'min-width': switchUsdGestion
                                  ? '100px'
                                  : '200px'
                              }"
                            >
                              <div class="cellListE1">
                                <span>{{ itemsDos.Descripcion }}</span>
                              </div>
                            </div>

                            <div
                              *ngIf="switchUsdGestion"
                              class="cellListP"
                              style="width: 100px; min-width: 100px"
                            >
                              <div class="cellListE1">
                                <span>${{ itemsDos.USD | commaDecimal }}</span>
                              </div>
                            </div>

                            <div class="cellListP" style="">
                              <div class="cellListE1">
                                <span
                                  >${{ itemsDos.Origen | commaDecimal }}</span
                                >
                              </div>
                              <!--<div *ngIf="!switchUsdGestion" class="cellListE1 "><span>{{itemsDos.OrigenGestiones }}</span></div>-->
                            </div>

                            <div class="cellListP" style="">
                              <div class="cellListE1">
                                <span
                                  >${{
                                    itemsDos.TransitoUno | commaDecimal
                                  }}</span
                                >
                              </div>
                              <!--<div *ngIf="!switchUsdGestion" class="cellListE1 "><span>{{itemsDos.TransitoUnoGestiones }}</span></div>-->
                            </div>

                            <div class="cellListP" style="">
                              <div class="cellListE1">
                                <span
                                  >${{ itemsDos.Frontera | commaDecimal }}</span
                                >
                              </div>
                              <!--<div *ngIf="!switchUsdGestion" class="cellListE1 "><span>{{itemsDos.FronteraGestiones }}</span></div>-->
                            </div>

                            <div class="cellListP" style="">
                              <div class="cellListE1">
                                <span
                                  >${{
                                    itemsDos.TransitoDos | commaDecimal
                                  }}</span
                                >
                              </div>
                              <!--<div *ngIf="!switchUsdGestion" class="cellListE1 "><span>{{itemsDos.TransitoDosGestiones }}</span></div>-->
                            </div>

                            <div class="cellListP" style="">
                              <div class="cellListE1">
                                <span
                                  >${{ itemsDos.Destino | commaDecimal }}</span
                                >
                              </div>
                              <!--<div *ngIf="!switchUsdGestion" class="cellListE1 "><span>{{itemsDos.DestinoGestiones }}</span></div>-->
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--/////////****Fin GRid Expandible 2***-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--/////////****Fin GRid***-->
</div>
<br />
