import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CorteDiarioModel } from '../corte-diario.models';
import Enumerable from 'linq';

@Component({
  selector: 'modal-carga',
  templateUrl: './modal-carga.component.html',
  styleUrls: ['./modal-carga.component.css'],
})
export class ModalCargaComponent implements OnInit {
  public listCarga: Array<CorteDiarioModel.DetalleFactura> = [];
  public listCostosFacturacion: Array<CorteDiarioModel.CostosFacturacion> = [];
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      gestionSelected: CorteDiarioModel.GetGestionesAbiertasXRutas;
      usuarioid: string;
    }
  ) {}

  ngOnInit(): void {
    this.listCarga = [];
    const gestion = this.data.gestionSelected;

    if (gestion?.CostosFacturacion) {
      this.listCostosFacturacion.push(...gestion.CostosFacturacion);
    }

    // if (gestion?.GestionesHijas?.length > 0) {
    //   gestion.GestionesHijas.forEach((hija) => {
    //     if (hija?.CostosFacturacion) {
    //       this.listCostosFacturacion.push(...hija.CostosFacturacion);
    //     }
    //   });
    // }

    this.listCarga = Enumerable.from(this.listCostosFacturacion)
      .selectMany((s) => s.DetalleFactura)
      .toArray();
  }
}
