import { AfterContentInit, AfterViewInit } from '@angular/core';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import Enumerable from 'linq';
import { CallsAnalisisService } from '../../services/calls-analisis.service';
import { CorteDiarioModel } from '../corte-diario.models';
import { GraphByAnalisisComponent } from '../graph-by-analisis/graph-by-analisis.component';
import { CallHttpService, DialogVestaService } from '@vesta/vesta';

@Component({
  selector: 'modal-graph-by-analisis',
  templateUrl: './modal-graph-by-analisis.component.html',
  styleUrls: ['./modal-graph-by-analisis.component.css'],
})
export class ModalGraphByAnalisisComponent
  implements OnInit, AfterContentInit, AfterViewInit
{
  constructor(
    private serviceanalisis: CallsAnalisisService,
    private dialogService: DialogVestaService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      //GestionesXRuta: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>,
      plantillaSelected: CorteDiarioModel.PlantillasIndex;
      UsuarioId: string;
      rutasXUsuario: Array<CorteDiarioModel.RutasByUsuario>;
    }
  ) {}

  @ViewChild('graphanalisis', { static: false })
  graphanalisis: GraphByAnalisisComponent;

  public aduana: string = null;
  public Reload: boolean = false;

  public listGestionXRuta: Array<CorteDiarioModel.GetGestionesAbiertasXRutas>;

  ngOnInit(): void {
    let rutasWithSitios = [];
    let rutas = Enumerable.from(
      this.data.plantillaSelected.TemplateRutas
    ).toArray();
    rutas.forEach((r) => {
      rutasWithSitios.push({
        rutaId: r.Ruta.Id,
        SitioReferenciaId: r.SitioReferenciaId,
      });
    });

    this.GetGestionesAbiertasXRutas(rutasWithSitios);
  }

  ngAfterViewInit(): void {}

  ngAfterContentInit(): void {}

  GetGestionesAbiertasXRutas(rutas: any[]) {
    let query = {
      fechaInicio: null,
      fechaFin: null,
      rutas: rutas,
      // "sitioAduanaId": this.aduana,
      diasAntesxFechaUltimoSello: null,
      UsuarioId: this.data.UsuarioId,
    };
    // console.log('query', query);
    this.serviceanalisis.GetGestionesAbiertasXRutas(query).then((result) => {
      result.forEach((gestion) => {
        if (
          gestion.Estado.DisplayName === 'Finalizado' &&
          gestion.GestionesHijas.length > 0
        ) {
          gestion.GestionesHijas.forEach((hija) => {
            hija.Segmentacion = {
              TransitoUno: [],
              Frontera: [],
              TransitoDos: [],
              Destino: [],
              Origen: undefined,
            };

            if (
              hija.NumeroGestion == gestion.Segmentacion.Origen?.NumeroGestion
            ) {
              hija.Segmentacion.Origen = gestion.Segmentacion.Origen;
            }
            if (
              gestion.Segmentacion.TransitoUno.some(
                (st1) =>
                  st1.NumeroGestion == hija.NumeroGestion &&
                  (st1.SitioActual || st1.SegmentoActual)
              )
            ) {
              hija.Segmentacion.TransitoUno = [];
              hija.Segmentacion.TransitoUno = Enumerable.from(
                gestion.Segmentacion.TransitoUno
              )
                .where(
                  (s) =>
                    s.NumeroGestion == hija.NumeroGestion &&
                    (s.SitioActual || s.SegmentoActual)
                )
                .toArray();
            }

            if (
              gestion.Segmentacion.Frontera.some(
                (st1) =>
                  st1.NumeroGestion == hija.NumeroGestion && st1.SitioActual
              )
            ) {
              hija.Segmentacion.Frontera = [];

              hija.Segmentacion.Frontera = Enumerable.from(
                gestion.Segmentacion.Frontera
              )
                .where(
                  (s) => s.NumeroGestion == hija.NumeroGestion && s.SitioActual
                )
                .toArray();
            }

            if (
              gestion.Segmentacion.TransitoDos.some(
                (st1) =>
                  st1.NumeroGestion == hija.NumeroGestion &&
                  (st1.SitioActual || st1.SegmentoActual)
              )
            ) {
              hija.Segmentacion.TransitoDos = [];

              hija.Segmentacion.TransitoDos = Enumerable.from(
                gestion.Segmentacion.TransitoDos
              )
                .where(
                  (s) =>
                    s.NumeroGestion == hija.NumeroGestion &&
                    (s.SitioActual || s.SegmentoActual)
                )
                .toArray();
            }
            if (
              gestion.Segmentacion.Destino.some(
                (st1) =>
                  st1.NumeroGestion == hija.NumeroGestion && st1.SitioActual
              )
            ) {
              hija.Segmentacion.Destino = [];

              hija.Segmentacion.Destino = Enumerable.from(
                gestion.Segmentacion.Destino
              )
                .where(
                  (s) => s.NumeroGestion == hija.NumeroGestion && s.SitioActual
                )
                .toArray();
            }
          });
        } else {
          //quitar codigo de gestion que no sea el de madre si no es finalizada
          gestion.Segmentacion.Origen.SitioActual =
            gestion.NumeroGestion === gestion.Segmentacion.Origen.NumeroGestion
              ? gestion.Segmentacion.Origen.SitioActual
              : false;
          gestion.Segmentacion.TransitoUno.forEach((s) => {
            s.SegmentoActual =
              gestion.NumeroGestion === s.NumeroGestion
                ? s.SegmentoActual
                : false;
            s.SitioActual =
              gestion.NumeroGestion === s.NumeroGestion
                ? s?.SitioActual
                : false;
          });
          gestion.Segmentacion.Frontera.forEach((s) => {
            s.SitioActual =
              gestion.NumeroGestion === s.NumeroGestion
                ? s?.SitioActual
                : false;
          });
          gestion.Segmentacion.TransitoDos.forEach((s) => {
            s.SegmentoActual =
              gestion.NumeroGestion === s.NumeroGestion
                ? s.SegmentoActual
                : false;
            s.SitioActual =
              gestion.NumeroGestion === s.NumeroGestion
                ? s?.SitioActual
                : false;
          });
          gestion.Segmentacion.Destino.forEach((s) => {
            s.SitioActual =
              gestion.NumeroGestion === s.NumeroGestion
                ? s?.SitioActual
                : false;
          });
        }
      });

      result.forEach((gestion) => {
        let gestionHijaNewOrigen: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
          [];
        let gestionHijaNewT1: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
          [];
        let gestionHijaNewFrontera: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
          [];
        let gestionHijaNewT2: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
          [];
        let gestionHijaNewDestino: Array<CorteDiarioModel.GetGestionesAbiertasXRutas> =
          [];
        //Mover gestiones hijas a su respectivo segmento
        if (
          gestion.Estado.DisplayName == 'Finalizado' &&
          gestion.GestionesHijas.length > 0
        ) {
          let flagEliminarMadre = false;
          gestion.GestionesHijas.forEach((gh) => {
            if (gh.Segmentacion?.Origen?.SitioActual == true) {
              gestionHijaNewOrigen.push(gh);
            } else if (
              gh.Segmentacion?.TransitoUno.some(
                (st1) => st1.SegmentoActual == true || st1.SitioActual == true
              )
            ) {
              gestionHijaNewT1.push(gh);
            } else if (
              gh.Segmentacion?.Frontera?.some((st1) => st1.SitioActual == true)
            ) {
              gestionHijaNewFrontera.push(gh);
            } else if (
              gh.Segmentacion?.TransitoDos?.some(
                (st1) => st1.SegmentoActual == true || st1.SitioActual == true
              )
            ) {
              gestionHijaNewT2.push(gh);
            } else if (
              gh.Segmentacion?.Destino?.some((st1) => st1.SitioActual == true)
            ) {
              gestionHijaNewDestino.push(gh);
            }
          });

          if (gestionHijaNewOrigen.length > 0) {
            const newGestion = JSON.parse(JSON.stringify(gestion));

            if (gestionHijaNewOrigen.length == gestion.GestionesHijas.length) {
              //si tienen el mismo tama;o modificar la gestion iterada
              //sino modificaragregar la copia
              gestion.Segmentacion.Origen.SitioActual = true;
            } else {
              //cambiamos la segmentacion de donde estan las nuevas hijas que se encontraron
              newGestion.Segmentacion.Origen.SitioActual = true;
              //poner los demas segmentos en false
              newGestion.Segmentacion.TransitoUno.forEach((tuno) => {
                tuno.SitioActual = false;
                tuno.SegmentoActual = false;
              });
              newGestion.Segmentacion.Frontera.forEach((tuno) => {
                tuno.SitioActual = false;
              });
              newGestion.Segmentacion.TransitoDos.forEach((tuno) => {
                tuno.SitioActual = false;
                tuno.SegmentoActual = false;
              });
              newGestion.Segmentacion.Destino.forEach((tuno) => {
                tuno.SitioActual = false;
              });
              newGestion.GestionesHijas = gestionHijaNewOrigen;

              result.push(newGestion);

              //si hago push de la copia entonces elimininar la original
              flagEliminarMadre = true;
            }
          }

          if (gestionHijaNewT1.length > 0) {
            const newGestion = JSON.parse(JSON.stringify(gestion));

            if (gestionHijaNewT1.length == gestion.GestionesHijas.length) {
              //si tienen el mismo tama;o modificar la gestion iterada
              //sino modificaragregar la copia
              if (gestion.Segmentacion.TransitoUno[0].SitioActual != null) {
                gestion.Segmentacion.TransitoUno[0].SitioActual = true;
              }
              if (gestion.Segmentacion.TransitoUno[0].SegmentoActual != null) {
                gestion.Segmentacion.TransitoUno[0].SegmentoActual = true;
              }
            } else {
              //cambiamos la segmentacion de donde estan las nuevas hijas que se encontraron
              newGestion.Segmentacion.Origen.SitioActual = false;
              //poner los demas segmentos en false
              newGestion.Segmentacion.TransitoUno =
                gestionHijaNewT1[0].Segmentacion.TransitoDos;
              newGestion.Segmentacion?.Frontera?.forEach((tuno) => {
                tuno.SitioActual = false;
              });
              newGestion.Segmentacion?.TransitoDos?.forEach((tuno) => {
                if (tuno.SitioActual != null) {
                  tuno.SitioActual = false;
                }
                if (tuno.SegmentoActual != null) {
                  tuno.SegmentoActual = false;
                }
              });
              newGestion.Segmentacion?.Destino?.forEach((tuno) => {
                tuno.SitioActual = false;
              });
              newGestion.GestionesHijas = gestionHijaNewT1;

              result.push(newGestion);
              //si hago push de la copia entonces elimininar la original
              flagEliminarMadre = true;
            }
          }

          if (gestionHijaNewFrontera.length > 0) {
            const newGestion = JSON.parse(JSON.stringify(gestion));

            if (
              gestionHijaNewFrontera.length == gestion.GestionesHijas.length
            ) {
              //si tienen el mismo tama;o modificar la gestion iterada
              //sino modificaragregar la copia
              if (gestion.Segmentacion?.Frontera[0]?.SitioActual != null) {
                gestion.Segmentacion.Frontera[0].SitioActual = true;
              }
            } else {
              //cambiamos la segmentacion de donde estan las nuevas hijas que se encontraron
              newGestion.Segmentacion.Origen.SitioActual = false;
              //poner los demas segmentos en false
              newGestion.Segmentacion?.TransitoUno?.forEach((tuno) => {
                if (tuno.SitioActual != null) {
                  tuno.SitioActual = false;
                }
                if (tuno.SegmentoActual != null) {
                  tuno.SegmentoActual = false;
                }
              });
              newGestion.Segmentacion?.Frontera?.forEach((tuno) => {
                tuno.SitioActual = true;
              });
              newGestion.Segmentacion?.TransitoDos?.forEach((tuno) => {
                if (tuno.SitioActual != null) {
                  tuno.SitioActual = false;
                }
                if (tuno.SegmentoActual != null) {
                  tuno.SegmentoActual = false;
                }
              });
              newGestion.Segmentacion?.Destino?.forEach((tuno) => {
                tuno.SitioActual = false;
              });
              newGestion.GestionesHijas = gestionHijaNewFrontera;

              result.push(newGestion);
            }
          }

          if (gestionHijaNewT2.length > 0) {
            const newGestion = JSON.parse(JSON.stringify(gestion));

            if (gestionHijaNewT2.length == gestion.GestionesHijas.length) {
              gestion.Segmentacion.TransitoDos =
                gestionHijaNewT2[0]?.Segmentacion?.TransitoDos;
              //si tienen el mismo tama;o modificar la gestion iterada
              //sino modificaragregar la copia
              if (
                gestion.Segmentacion?.TransitoDos?.some(
                  (s) => s.SitioActual != null
                )
              ) {
                let find = gestion.Segmentacion?.TransitoDos?.find(
                  (s) => s.SitioActual != null
                ).SitioActual;
                find = true;
              }

              if (
                gestion.Segmentacion?.TransitoDos?.some(
                  (s) => s.SegmentoActual != null
                )
              ) {
                let find = gestion.Segmentacion?.TransitoDos?.find(
                  (s) => s.SegmentoActual != null
                ).SegmentoActual;
                find = true;
              }
            } else {
              //cambiamos la segmentacion de donde estan las nuevas hijas que se encontraron
              newGestion.Segmentacion.Origen.SitioActual = false;
              //poner los demas segmentos en false
              newGestion.Segmentacion?.TransitoUno?.forEach((tuno) => {
                if (tuno.SitioActual != null) {
                  tuno.SitioActual = false;
                }
                if (tuno.SegmentoActual != null) {
                  tuno.SegmentoActual = false;
                }
              });
              newGestion.Segmentacion?.Frontera?.forEach((tuno) => {
                tuno.SitioActual = false;
              });
              newGestion.Segmentacion.TransitoDos =
                gestionHijaNewT2[0].Segmentacion.TransitoDos;

              newGestion.Segmentacion?.Destino?.forEach((tuno) => {
                tuno.SitioActual = false;
              });
              newGestion.GestionesHijas = gestionHijaNewT2;

              result.push(newGestion);
              //si hago push de la copia entonces elimininar la original
              flagEliminarMadre = true;
            }
          }

          if (gestionHijaNewDestino.length > 0) {
            const newGestion = JSON.parse(JSON.stringify(gestion));

            if (gestionHijaNewDestino.length == gestion.GestionesHijas.length) {
              //si tienen el mismo tama;o modificar la gestion iterada
              //sino modificaragregar la copia
              gestion.Segmentacion.Destino[0].SitioActual = true;
            } else {
              //cambiamos la segmentacion de donde estan las nuevas hijas que se encontraron
              newGestion.Segmentacion.Origen.SitioActual = false;
              //poner los demas segmentos en false
              newGestion.Segmentacion?.TransitoUno?.forEach((tuno) => {
                if (tuno.SitioActual != null) {
                  tuno.SitioActual = false;
                }
                if (tuno.SegmentoActual != null) {
                  tuno.SegmentoActual = false;
                }
              });
              newGestion.Segmentacion?.Frontera?.forEach((tuno) => {
                tuno.SitioActual = false;
              });
              newGestion.Segmentacion?.TransitoDos?.forEach((tuno) => {
                if (tuno.SitioActual != null) {
                  tuno.SitioActual = false;
                }
                if (tuno.SegmentoActual != null) {
                  tuno.SegmentoActual = false;
                }
              });
              newGestion.Segmentacion?.Destino?.forEach((tuno) => {
                tuno.SitioActual = true;
              });
              newGestion.GestionesHijas = gestionHijaNewDestino;

              result.push(newGestion);
              //si hago push de la copia entonces elimininar la original
              flagEliminarMadre = true;
            }
          }

          if (flagEliminarMadre) {
            let findIndex = result.findIndex(
              (s) => s.NumeroGestion == gestion.NumeroGestion
            );
            if (findIndex != -1) {
              result.splice(findIndex, 1);
            }
          }
        }

        gestion.TiempoProceso = parseFloat(gestion.TiempoProceso.toFixed(2));
        gestion.CostoTotal = parseFloat(gestion.CostoTotal.toFixed(2));
        //gestion.Segmentacion.TransitoUno.forEach(tuno => {
        //  let primersitio = Enumerable.from(gestion.Ruta.SitiosPorRuta).where(s => s.Id == tuno.SitioOrigen).select(s => s.Nombre).firstOrDefault();
        //  let segsitio = Enumerable.from(gestion.Ruta.SitiosPorRuta).where(s => s.Id == tuno.SitioDestino).select(s => s.Nombre).firstOrDefault();
        //  tuno.Nombre = primersitio + '/' + segsitio;
        //})
        //gestion.Segmentacion.TransitoDos.forEach(tuno => {
        //  let primersitio = Enumerable.from(gestion.Ruta.SitiosPorRuta).where(s => s.Id == tuno.SitioOrigen).select(s => s.Nombre).firstOrDefault();
        //  let segsitio = Enumerable.from(gestion.Ruta.SitiosPorRuta).where(s => s.Id == tuno.SitioDestino).select(s => s.Nombre).firstOrDefault();
        //  tuno.Nombre = primersitio + '/' + segsitio;
        //})
      });
      const acumuladorOrigen = [];
      const acumuladorDestino = [];
      const acumuladorTransitoUno = [];
      const acumuladorTransitoDos = [];
      const acumuladorFrontera = [];
      this.listGestionXRuta = result;
      //  .reduce((acumuladores, gestion) => {
      //  if (gestion.Segmentacion.Origen.SitioActual === true) {
      //    //Antes de agregar el objeto gestion a acumuladorOrigen,
      //    //se verifica que no exista ya un elemento con el mismo NumeroGestion en acumuladorOrigen.
      //    if (
      //      !acumuladorOrigen.some(
      //        (g) => g.NumeroGestion == gestion.NumeroGestion
      //      )
      //    ) {
      //      acumuladorOrigen.push(gestion);
      //    }
      //  }

      //  if (gestion.Segmentacion.Destino.some((s) => s.SitioActual === true)) {
      //    if (
      //      !acumuladorDestino.some(
      //        (g) => g.NumeroGestion == gestion.NumeroGestion
      //      )
      //    ) {
      //      acumuladorDestino.push(gestion);
      //    }
      //  }

      //  if (
      //    gestion.Segmentacion.TransitoUno.some(
      //      (s) => s.SitioActual === true || s.SegmentoActual === true
      //    )
      //  ) {
      //    if (
      //      !acumuladorTransitoUno.some(
      //        (g) => g.NumeroGestion == gestion.NumeroGestion
      //      )
      //    ) {
      //      acumuladorTransitoUno.push(gestion);
      //    }
      //  }

      //  if (
      //    gestion.Segmentacion.TransitoDos.some(
      //      (s) => s.SitioActual === true || s.SegmentoActual === true
      //    )
      //  ) {
      //    if (
      //      !acumuladorTransitoDos.some(
      //        (g) => g.NumeroGestion == gestion.NumeroGestion
      //      )
      //    ) {
      //      acumuladorTransitoDos.push(gestion);
      //    }
      //  }

      //  if (gestion.Segmentacion.Frontera.some((s) => s.SitioActual === true)) {
      //    if (
      //      !acumuladorFrontera.some(
      //        (g) => g.NumeroGestion == gestion.NumeroGestion
      //      )
      //    ) {
      //      acumuladorFrontera.push(gestion);
      //    }
      //  }
      //  return acumuladores;
      //}, []);
      //// Combina todos los acumuladores y asigna a this.listGestionXRuta
      //this.listGestionXRuta = [
      //  ...acumuladorOrigen,
      //  ...acumuladorDestino,
      //  ...acumuladorTransitoUno,
      //  ...acumuladorTransitoDos,
      //  ...acumuladorFrontera,
      //];

      this.GetData();
    });
  }

  GetData() {
    //this.listGestionXRuta = this.data.GestionesXRuta;
    this.graphanalisis.UsuarioId = this.data.UsuarioId;
    this.graphanalisis.PlantillaSelected = this.data.plantillaSelected;
    this.graphanalisis.ngData();

    //Todas las gestiones que retorna el metodo, sirven para excluidos
    this.graphanalisis.AlllistGestiones = this.listGestionXRuta;
    this.graphanalisis.listGestiones = this.listGestionXRuta;
    this.graphanalisis.listrutasXUsuario = this.data.rutasXUsuario;

    //gestiones que se trabajaran exactamente por la ruta de la plantilla y si hay tiempo max y min filtrarlas, porque la plantilla solo trabajara con esas
    //igual que el comparar

    if (
      this.data.plantillaSelected?.DiaMin >= 0 &&
      this.data.plantillaSelected?.DiaMax > 0
    ) {
      this.graphanalisis.listGestiones = Enumerable.from(this.listGestionXRuta)
        .where(
          (s) =>
            s.TiempoProceso >= this.data.plantillaSelected?.DiaMin &&
            s.TiempoProceso <= this.data.plantillaSelected?.DiaMax
        )
        .toArray();
    } else {
      this.graphanalisis.listGestiones = this.listGestionXRuta;
    }

    this.graphanalisis.EventObtenerGestiones(this.listGestionXRuta, true);
  }

  dataReload(datos: boolean) {
    this.Reload = datos;
  }
  gb;
}
