<!--<header  data-role="appbar" data-expand="true" >
  <a class="app-bar-menu" routerLink="/menu">
    <span id="toggle-tiles-dropdown" class="mif-apps mif-2x"></span>
  </a>
  <div class="app-bar-divider"></div>


  <ul class="app-bar-menu small-dropdown place-right">
    <li>
      <a class="dropdown-toggle place-right right app-bar-element">{{user.Name}}</a>
      <ul class="d-menu" data-role="dropdown">
        <li><a (click)="Logout()">Logout</a></li>
        <li><a href="https://vestaidentityserver.azurewebsites.net/Identity/Account/Manage" target="_blank">Configuracion</a></li>
      </ul>
    </li>
  </ul>
  <div class="app-bar-divider place-right"></div>-->
<!--<ui-button-proyectos [idUsuario]="user.Id" [idSistema]="20" [nombreUsuario]="user.Name"></ui-button-proyectos>-->
<!--<div class="app-bar-divider place-right"></div>

  <ul class="app-bar-menu small-dropdown place-right">
    <li>
      <a style="width: 60px;" (click)="NavOpen()"><div class="img" [ngClass]="_navBarService.Src"></div></a>
    </li>
  </ul>
  <div class="app-bar-divider place-right"></div>
</header>-->
<div *ngIf="modulo !== 'maincortediario'">
  <div
    class="fixed-top bg-darkCyan fg-white"
    data-role="appbar"
    data-expand="true"
    style="display: block"
  >
    <ul class="t-menu open bg-darkCyan fg-white" *ngIf="user != null">
      <li>
        <a><span class="mif-apps icon"></span></a>
        <ul class="d-menu" data-role="dropdown">
          <li *ngFor="let path of paths">
            <a class="dropdown-toggle">{{ path.dropdownName }}</a>
            <ul class="d-menu" data-role="dropdown">
              <li *ngFor="let childrenPath of path.childrensPaths">
                <a class="dropdown-toggle">{{ childrenPath.Name }}</a>

                <ul class="d-menu" data-role="dropdown">
                  <li *ngFor="let child of childrenPath.childsOfChilds">
                    <a [routerLink]="child.routerLink" [href]="child.href">{{
                      child.Name
                    }}</a>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
    <!--<ul class="app-bar-menu place-right">
      <li><a href="#"><span class="mif-apps icon"></span></a></li>
    </ul>-->

    <ul class="app-bar-menu small-dropdown place-right" *ngIf="user != null">
      <li class="put-left">
        <a class="dropdown-toggle app-bar-element"
          ><span class="mif-user icon"></span> {{ user.Name }}</a
        >
        <ul class="d-menu put-right" data-role="dropdown">
          <li><a (click)="Logout()">Logout</a></li>
          <li>
            <a
              href="https://vestaidentityserver.azurewebsites.net/Identity/Account/Manage"
              target="_blank"
              >Configuracion</a
            >
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
