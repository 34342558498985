<div mat-dialog-title class="rowFlexNoWrap">
  <div class="rowText">Gestiones</div>
  <div class="rowText">
    Total Gestiones Madre: {{ totalGestionesMadre }} Total Gestiones Hija:
    {{ totalGestionesHija }}
  </div>

  <button
    mat-button
    mat-dialog-close
    class="close-button"
    style="margin-left: auto"
  >
    <img
      src="../../../assets/icons/cerrar.png"
      alt="Alternate Text"
      style="height: 30px; width: 30px"
    />
  </button>
</div>

<mat-dialog-content
  class="mat-typography"
  style="height: 91%; max-height: 91% !important"
>
  <!--************GRID***********-->
  <!--<div class="SectionGrids">-->
  <!--************Encabezado***********-->

  <div
    class="flexRowNoWrap EncabezadoGridR1"
    style="
      justify-content: space-between;
      flex-wrap: nowrap;
      min-height: 36px;
      min-width: 2500px;
    "
  >
    <div class="cellListP" style="width: 40px; min-width: 40px">
      <div class="cellListE1">&nbsp;</div>
    </div>

    <div class="cellListP" style="width: 15px; min-width: 15px">
      <div class="cellListE1">&nbsp;</div>
    </div>

    <div class="cellListP" style="min-width: 130px">
      <div class="cellListE1"><span>Gestion</span></div>
    </div>

    <div class="cellListP" style="min-width: 130px">
      <div class="cellListE1"><span>Proveedor</span></div>
    </div>

    <div class="cellListP" style="min-width: 80px">
      <div class="cellListE1"><span>USD</span></div>
    </div>

    <div class="cellListP" style="min-width: 100px">
      <div class="cellListE1"><span>Estado</span></div>
    </div>

    <div class="cellListP" style="min-width: 100px">
      <div class="cellListE1"><span>ETA</span></div>
    </div>

    <div class="cellListP" style="min-width: 200px">
      <div class="cellListE1"><span>Sitio Actual /Segmento Actual </span></div>
    </div>

    <div class="cellListP" style="min-width: 100px">
      <div class="cellListE1"><span>Ultimo Sello</span></div>
    </div>

    <div class="cellListP" style="min-width: 100px">
      <div class="cellListE1"><span>Fecha Ultimo Sello </span></div>
    </div>

    <div class="cellListP" style="min-width: 100px">
      <div class="cellListE1"><span>No. Factura Comercial </span></div>
    </div>

    <div class="cellListP" style="min-width: 120px">
      <div class="cellListE1"><span>Doc. Transporte</span></div>
    </div>

    <div class="cellListP" style="min-width: 120px">
      <div class="cellListE1"><span>Placa Cabezal</span></div>
    </div>

    <div class="cellListP" style="min-width: 120px">
      <div class="cellListE1"><span>Placa Furgon</span></div>
    </div>

    <div class="cellListP" style="min-width: 400px; max-width: 400px">
      <div class="cellListE1"><span>Notas </span></div>
    </div>

    <div class="cellListP minmaxwidth55">
      <div class="cellListE1"><span>Eventos</span></div>
    </div>

    <div class="cellListP minmaxwidth55">
      <div class="cellListE1"><span>Alertas</span></div>
    </div>

    <div class="cellListP minmaxwidth75">
      <div class="cellListE1"><span>Incidencias </span></div>
    </div>

    <div class="cellListP minmaxwidth55">
      <div class="cellListE1"><span>Casos</span></div>
    </div>

    <div class="cellListP minmaxwidth55">
      <div class="cellListE1"><span>Carga</span></div>
    </div>

    <div class="cellListP minmaxwidth55">
      <div class="cellListE1"><span>Bitacora</span></div>
    </div>
  </div>
  <!--************FILTROS***********-->

  <div class="flexRowNoWrap headerFiltros" style="">
    <div class="cellListP" style="width: 40px; min-width: 40px">
      <div class="cellListE1">&nbsp;</div>
    </div>

    <div class="cellListP" style="width: 15px; min-width: 15px">
      <div class="cellListE1">&nbsp;</div>
    </div>

    <div class="cellListP" style="min-width: 130px">
      <div class="cellListE1">
        <input
          type="text"
          name="NumeroGestion"
          value=""
          (input)="EventFiltros($event, 'NumeroGestion')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 130px">
      <div class="cellListE1">
        <input
          type="text"
          name="ConsignatarioNombre"
          value=""
          (input)="EventFiltros($event, 'ConsignatarioNombre')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 80px">
      <div class="cellListE1">
        <input
          type="text"
          name="CostoTotal"
          value=""
          (input)="EventFiltros($event, 'CostoTotal')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 100px">
      <div class="cellListE1">
        <input
          type="text"
          name="Estado"
          value=""
          (input)="EventFiltros($event, 'Estado')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 100px">
      <div class="cellListE1">
        <input
          type="text"
          name="ETA"
          value=""
          (input)="EventFiltros($event, 'ETA')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 200px">
      <div class="cellListE1">
        <input
          type="text"
          name="SitioActual"
          value=""
          (input)="EventFiltros($event, 'SitioActual')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 100px">
      <div class="cellListE1">
        <input
          type="text"
          name="UltimoSelloNombre"
          value=""
          (input)="EventFiltros($event, 'UltimoSelloNombre')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 100px">
      <div class="cellListE1">
        <input
          type="text"
          name="UltimoSello"
          value=""
          (input)="EventFiltros($event, 'UltimoSello')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 100px">
      <div class="cellListE1">
        <input
          type="text"
          name="CostosFacturacion"
          value=""
          (input)="EventFiltros($event, 'CostosFacturacion')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 120px">
      <div class="cellListE1">
        <input
          type="text"
          name="DocumentosTransporte"
          value=""
          (input)="EventFiltros($event, 'DocumentosTransporte')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 120px">
      <div class="cellListE1">
        <input
          type="text"
          name="PlacaCabezal"
          value=""
          (input)="EventFiltros($event, 'PlacaCabezal')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 120px">
      <div class="cellListE1">
        <input
          type="text"
          name="PlacaFurgon"
          value=""
          (input)="EventFiltros($event, 'PlacaFurgon')"
        />
      </div>
    </div>

    <div class="cellListP" style="min-width: 400px; max-width: 400px">
      <div class="cellListE1">
        <input
          type="text"
          name="Notas"
          value=""
          (input)="EventFiltros($event, 'Notas')"
        />
      </div>
    </div>

    <div class="cellListP minmaxwidth55">
      <div class="cellListE1">
        <input
          type="text"
          name="Eventos"
          value=""
          (input)="EventFiltros($event, 'Eventos')"
        />
      </div>
    </div>

    <div class="cellListP minmaxwidth55">
      <div class="cellListE1">
        <input
          type="text"
          name="Alertas"
          value=""
          (input)="EventFiltros($event, 'Alertas')"
        />
      </div>
    </div>

    <div class="cellListP minmaxwidth75">
      <div class="cellListE1">
        <input
          type="text"
          name="Incidencias"
          value=""
          (input)="EventFiltros($event, 'Incidencias')"
        />
      </div>
    </div>

    <div class="cellListP minmaxwidth55">
      <div class="cellListE1">
        <input
          type="text"
          name="Casos"
          value=""
          (input)="EventFiltros($event, 'Casos')"
        />
      </div>
    </div>

    <div class="cellListP minmaxwidth55">
      <div class="cellListE1">
        <input type="text" name="Carga" value="" />
      </div>
    </div>

    <div class="cellListP minmaxwidth55">
      <div class="cellListE1"><span>&nbsp;</span></div>
    </div>
  </div>

  <!--************Body grids***********-->

  <div
    class="subsection"
    style="height: 84%; min-width: 2500px; overflow-y: auto; overflow-x: hidden"
  >
    <div *ngFor="let item of newArrayGestion; let i = index">
      <div class="flexRowNoWrap rowGrid">
        <div class="cellListP" style="width: 40px; min-width: 40px">
          <div
            class="cellListE1 c-pointer"
            (click)="item.Expandible = !item.Expandible"
          >
            <img
              *ngIf="!item.Expandible"
              src="../../../assets/icons/arrowDownBlack1.png"
              alt="Alternate Text"
            />
            <img
              *ngIf="item.Expandible"
              src="../../../assets/icons/arrowUpBlack1.png"
              alt="Alternate Text"
            />
          </div>
        </div>

        <div class="cellListP" style="min-width: 15px; max-width: 15px">
          <div class="cellListE1">
            <span>{{ i + 1 }}</span>
          </div>
        </div>

        <div class="cellListP" style="min-width: 130px">
          <div class="cellListE1">
            <span>{{ item.NumeroGestion }}</span>
          </div>
        </div>

        <div class="cellListP" style="min-width: 140px">
          <div class="cellListE1">
            <span>{{ item.ConsignatarioNombre }}</span>
          </div>
        </div>

        <div class="cellListP" style="min-width: 80px">
          <div class="cellListE1">
            <span>${{ item.CostoTotal | commaDecimal }}</span>
          </div>
        </div>

        <div class="cellListP" style="min-width: 100px">
          <div class="cellListE1">
            <span>{{ item.Estado.DisplayName }}</span>
          </div>
        </div>

        <div class="cellListP" style="min-width: 100px">
          <div class="cellListE1">
            <span>{{ item.ETA }}</span>
          </div>
        </div>

        <div class="cellListP" style="min-width: 200px">
          <div class="cellListE1">
            <span>{{ item.SitioActual }}</span>
          </div>
        </div>

        <div class="cellListP" style="min-width: 100px">
          <div class="cellListE1">
            <span>{{ item.UltimoSelloNombre }}</span>
          </div>
        </div>

        <div class="cellListP" style="min-width: 100px">
          <div class="cellListE1">
            <span>{{ item.UltimoSello }}</span>
          </div>
        </div>

        <!--<div class="cellListP" style="min-width: 100px" #Facturas id="Facturas">
          <button
            class="buttonTransparente"
            style="width: 38px"
            (mouseenter)="Eventtooltip(item, 'Facturas')"
            (mouseleave)="Eventtooltip(null, '')"
          >
            (click)="EventBtnModalFamilia(plantillaPriv.Familias)">
            <img
              src="../../../assets/icons/list.png"
              alt="Alternate Text"
              style="height: 35px"
            />
          </button>
        </div>-->

        <div
          class="cellListP"
          style="cursor: pointer"
          #Facturas
          id="Facturas"
          (click)="MostrarFacturas(item)"
        >
          <div class="cellListE1">
            <span>{{ item.CostosFacturacion[0]?.NumeroFactura }}</span>
          </div>
        </div>

        <!--<div class="cellListP " style="min-width: 100px" #DocumentoT id="DocumentoT">
    <button class="buttonTransparente" style=" width: 38px;" (mouseenter)="Eventtooltip(item,'DocumentoT')" (mouseleave)="Eventtooltip(null,'')">
      <img src="../../../assets/icons/list.png" alt="Alternate Text" style="height: 35px" />
    </button>
  </div>-->

        <div class="cellListP" style="min-width: 120px">
          <div class="cellListE1">
            <span> {{ item.DocumentosTransporte[0]?.Referencia }}</span>
          </div>
        </div>

        <div class="cellListP" style="min-width: 120px">
          <div class="cellListE1">
            <span> {{ item?.PlacaCabezal }}</span>
          </div>
        </div>

        <div class="cellListP" style="min-width: 120px">
          <div class="cellListE1">
            <span> {{ item?.PlacaFurgon }}</span>
          </div>
        </div>

        <div
          class="cellListP"
          style="cursor: pointer; min-width: 400px; max-width: 400px"
          id="Notas"
          (click)="MostrarNotas(item)"
        >
          <div class="cellListE1">
            <span>{{ obtenerUltimaObservacion(item) }}</span>
          </div>
        </div>

        <div
          class="cellListP minmaxwidth55"
          style="cursor: pointer"
          id="Eventos"
          (click)="MostrarEventos(item)"
        >
          <div class="cellListE1">
            <span
              [ngStyle]="{
                color: mostrarEventosAbiertos(item).tieneAbiertos
                  ? '#c38d08'
                  : 'black'
              }"
            >
              {{ mostrarEventosAbiertos(item).totalEventos }}
            </span>
          </div>
        </div>

        <div
          class="cellListP minmaxwidth55"
          style="cursor: pointer"
          id="Alertas"
          (click)="MostrarAlertas(item)"
        >
          <div class="cellListE1">
            <span
              [ngStyle]="{
                color:
                  mostrarAlertasColor(item).totalAlertas > 0
                    ? '#c38d08'
                    : 'black'
              }"
            >
              {{ mostrarAlertasColor(item).totalAlertas }}
            </span>
          </div>
        </div>

        <div class="cellListP minmaxwidth75" style="">
          <div class="cellListE1">
            <span>{{ item.Incidencias?.length }}</span>
          </div>
        </div>

        <div class="cellListP minmaxwidth55" style="">
          <div class="cellListE1">
            <span>{{ item.Casos?.length }}</span>
          </div>
        </div>

        <div class="cellListP minmaxwidth55" style="">
          <div class="cellListE1">
            <button
              class="buttonTransparente transparent"
              style="min-width: 42px; height: 30px"
              (click)="EventModalCarga(item)"
            >
              <img
                src="../../../assets/icons/Cargas.png"
                alt="Alternate Text"
                style="height: 32px"
              />
            </button>
          </div>
        </div>

        <div class="cellListP minmaxwidth55" style="">
          <div class="cellListE1">
            <button
              class="buttonTransparente transparent"
              style="min-width: 42px; height: 30px"
              (click)="EventModalBitacora(item)"
            >
              <img
                src="../../../assets/icons/bitacora.png"
                alt="Alternate Text"
                style="height: 32px"
              />
            </button>
          </div>
        </div>

        <!--<div class="cellListP ">
    <button class="buttonTransparente transparent" style="min-width: 36px; height: 30px; " (click)="EventChangeFronteraModal()">
      <img src="../../../assets/icons/cambiar.png" alt="Alternate Text" style="height: 20px;" />
    </button>
  </div>-->
      </div>

      <!--**********expandible 1-->
      <div *ngIf="item.Expandible" class="SectionGrids SectionGridsH">
        <!--************Body grids***********-->
        <div>
          <div class="subsection" style="background-color: lightgray">
            <div *ngFor="let gestionhija of item.GestionesHijas; let i = index">
              <div class="flexRowNoWrap rowGrid">
                <div class="cellListP" style="width: 40px; min-width: 40px">
                  <div class="cellListE1">&nbsp;</div>
                </div>

                <div class="cellListP" style="min-width: 15px; max-width: 15px">
                  <div class="cellListE1">
                    <span>{{ i + 1 }}</span>
                  </div>
                </div>

                <div class="cellListP" style="min-width: 130px">
                  <div class="cellListE1">
                    <span>{{ gestionhija.NumeroGestion }}</span>
                  </div>
                </div>

                <div class="cellListP" style="min-width: 130px">
                  <div class="cellListE1">
                    <span>{{ item.ConsignatarioNombre }}</span>
                  </div>
                </div>

                <div class="cellListP" style="min-width: 80px">
                  <div class="cellListE1">
                    <span>${{ gestionhija.CostoTotal | commaDecimal }}</span>
                  </div>
                </div>

                <div class="cellListP" style="min-width: 100px">
                  <div class="cellListE1">
                    <span>{{ gestionhija.Estado.DisplayName }}</span>
                  </div>
                </div>

                <div class="cellListP" style="min-width: 100px">
                  <div class="cellListE1">
                    <span>{{ gestionhija.ETA }}</span>
                  </div>
                </div>

                <div class="cellListP" style="min-width: 200px">
                  <div class="cellListE1">
                    <span>{{ gestionhija.SitioActualModal }}</span>
                  </div>
                </div>

                <div class="cellListP" style="min-width: 100px">
                  <div class="cellListE1">
                    <span>{{ gestionhija.UltimoSelloNombre }}</span>
                  </div>
                </div>

                <div class="cellListP" style="min-width: 100px">
                  <div class="cellListE1">
                    <span>{{ gestionhija.UltimoSello }}</span>
                  </div>
                </div>

                <div
                  class="cellListP"
                  style="min-width: 100px"
                  #Facturas
                  id="Facturas"
                  style="cursor: pointer"
                  (click)="MostrarFacturas(gestionhija)"
                >
                  <div class="cellListE1">
                    <span>{{
                      gestionhija.CostosFacturacion[0]?.NumeroFactura
                    }}</span>
                  </div>
                </div>

                <div class="cellListP" style="min-width: 120px">
                  <div class="cellListE1">
                    <span>
                      {{
                        gestionhija.DocumentosTransporte[0]?.Referencia
                      }}</span
                    >
                  </div>
                </div>

                <div class="cellListP" style="min-width: 120px">
                  <div class="cellListE1">
                    <span> {{ gestionhija?.PlacaCabezal }}</span>
                  </div>
                </div>

                <div class="cellListP" style="min-width: 120px">
                  <div class="cellListE1">
                    <span> {{ gestionhija?.PlacaFurgon }}</span>
                  </div>
                </div>

                <div
                  class="cellListP"
                  style="cursor: pointer; min-width: 400px; max-width: 400px"
                  (click)="MostrarNotas(gestionhija)"
                >
                  <div class="cellListE1">
                    <span>{{ obtenerUltimaObservacion(gestionhija) }}</span>
                  </div>
                </div>

                <div
                  class="cellListP minmaxwidth55"
                  style="cursor: pointer"
                  (click)="MostrarEventos(gestionhija)"
                >
                  <div class="cellListE1">
                    <span
                      [ngStyle]="{
                        color: mostrarEventosAbiertos(gestionhija).tieneAbiertos
                          ? '#c38d08'
                          : 'black'
                      }"
                    >
                      {{ mostrarEventosAbiertos(gestionhija).totalEventos }}
                    </span>
                  </div>
                </div>

                <div
                  class="cellListP minmaxwidth55"
                  style="cursor: pointer"
                  (click)="MostrarAlertas(gestionhija)"
                >
                  <div class="cellListE1">
                    <span
                      [ngStyle]="{
                        color:
                          mostrarAlertasColor(gestionhija).totalAlertas > 0
                            ? '#c38d08'
                            : 'black'
                      }"
                    >
                      {{ mostrarAlertasColor(gestionhija).totalAlertas }}
                    </span>
                  </div>
                </div>

                <div class="cellListP minmaxwidth75" style="">
                  <div class="cellListE1">
                    <span>{{ gestionhija.Incidencias?.length }}</span>
                  </div>
                </div>

                <div class="cellListP minmaxwidth55" style="">
                  <div class="cellListE1">
                    <span>{{ gestionhija.Casos?.length }}</span>
                  </div>
                </div>

                <div class="cellListP minmaxwidth55" style="">
                  <div class="cellListE1">
                    <button
                      class="buttonTransparente transparent"
                      style="min-width: 42px; height: 30px"
                      (click)="EventModalCarga(gestionhija)"
                    >
                      <img
                        src="../../../assets/icons/Cargas.png"
                        alt="Alternate Text"
                        style="height: 32px"
                      />
                    </button>
                  </div>
                </div>

                <div class="cellListP minmaxwidth55" style="">
                  <div class="cellListE1">
                    <button
                      class="buttonTransparente transparent"
                      style="min-width: 42px; height: 30px"
                      (click)="EventModalBitacora(gestionhija)"
                    >
                      <img
                        src="../../../assets/icons/bitacora.png"
                        alt="Alternate Text"
                        style="height: 32px"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!--</div>-->
</mat-dialog-content>

<!--<mat-dialog-actions align="end">

  <button class="button" mat-button [mat-dialog-close]="true" cdkFocusInitial style=" height: 100%;">
    <img src="../../../assets/icons/save.png" alt="Alternate Text" />
    ACTUALIZAR
  </button>

</mat-dialog-actions>-->

<div *ngIf="showTooltip" class="tooltipItems" id="ModalFC">
  <!--<div *ngIf="showTooltip" class="tooltipItems" id="ModalFC" [ngStyle]="{'top':positionsArrays.top,'left': positionsArrays.left }">-->
  <div class="" role="document">
    <div class="">
      <div class="">
        <h6 class="modal-title1" id="exampleModalLabel">{{ title }}</h6>
      </div>
      <div class="" style="color: black; overflow-y: auto; height: 88%">
        <ul>
          <li *ngFor="let item of arrayListItem">
            {{ item.Descripcion }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
